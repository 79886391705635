.app-header hr {
  width: stretch;
  background-color: #d52b1e;
  border: 0;
  height: 3px !important;
  margin: 0;
  opacity: 1 !important;
}

.app-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-weight: 400;
  color: black;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  height: 10vh;
  width: 100vw;
}

.navigation-bar {
  overflow: hidden;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  width: 100vw;
}

.logo img {
  height: auto;
  width: 20vw;
  min-width: 200px;
}

.link-container {
  display: flex;
}

.nav-link {
  color: #d52b1e;
  text-decoration: none;
  min-width: 10vw;
  /* min-height: 5vh; */
  margin-right: 5vw;
  align-self: center;
}

.nav-link button#instructions {
  font-size: 1.5vw;
  font-weight: 400;
  color: #d52b1e;
  background: none;
  border: none;
  outline: 0;
  display: flex;
  align-items: center;
}

.nav-link a:hover {
 color:#d52b1e;
}
