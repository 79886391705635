.map-drawer-toggle-button,
.btn-secondary:focus {
  position: fixed;
  bottom: 5vh;
  left: 7vh;
  /* height: 4vh; */
}

.btn-sign-up,
.map-drawer-toggle-button,
.btn-secondary:focus {
  border-radius: 5px;
  z-index: 1;
  background-color: #d52b1e;
  color: white;
  border: 1px solid transparent;
  box-shadow: none;
  min-height: 5vh;
  width: 10vw;
  min-width: 15vw;
}

.map-drawer-toggle-button h4,
.btn-sign-up h4 {
  margin: 0;
  font-size: 1.5vw;
  text-align: center;
  padding:0.25rem;
}

.map-drawer {
  z-index: 1;
}

.btn-primary:focus:active,
.map-drawer-toggle-button:hover,
.map-drawer-toggle-button:active,
.btn-check:focus + .btn-secondary,
.btn-sign-up:active,
.btn-sign-up:hover {
  box-shadow: none;
  background-color: white;
  color: #d52b1e;
  border: #d52b1e 1px solid;
}

.map-card {
  height: auto;
  width: fit-content;
  background-image: "";
}
.collapseing.collapse-horizontal {
  height: fit-content;
}
.collapse,
.collapsing {
  position: absolute;
  bottom: 12vh;
  left: 0;
}
