body {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
micr-io {
  position: absolute;
top:10vh;
left:0;
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
} 
