.mini-map-link {
  position: absolute;
  background-size: contain;
  outline: none;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
}

.mini-map {
  width: 25vw;
  height: 35vw;
  position: relative;
  background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/Walls.png");
  display: flex;
  background-size: contain;
  background-color: white;
}

.card-body {
  padding: 0;
}

.mini-map #ULLxM {
  right: 3%;
  top: 10%;
  height: 30%;
  width: 28%;
  background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_auditorium_off.png");
}

.mini-map #ULLxM.active, .mini-map #ULLxM:hover {
 background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_auditorium_on.png");
}  

.mini-map #ZZKQV {
  background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_heart%20health_cat%20allergens_off.png");
  left: 5%;
  top: 12%;
  height: 30%;
  width: 35%;
}

.mini-map #ZZKQV.active, .mini-map #ZZKQV:hover {
    background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_heart%20health_cat%20allergens_on.png");

}  

.mini-map #UVQFp {
  background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_body%20condiditon%20score_off.png");
  left: 10%;
  top: 42%;
  height: 25%;
  width: 22%;
}

.mini-map #UVQFp.active, .mini-map #UVQFp:hover {
    background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_body%20condiditon%20score_on.png");
}  

.mini-map #WTlEs {
  background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_molecular%20nutrition_off.png");
  top: 36%;
  left: 45%;
  height: 30%;
  width: 30%;
}

.mini-map #WTlEs.active, .mini-map #WTlEs:hover {
    background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_molecular%20nutrition_on.png");
}  

.mini-map #NCYpB {
  background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_microbiome_off.png");
  right: 3%;
  top: 46%;
  width: 18%;
  height: 40%;
}

.mini-map #NCYpB.active, .mini-map #NCYpB:hover {
    background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_microbiome_on.png");
}  

.mini-map #fDNHH {
  background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_history%20of%20innovation_off.png");
  left: 26%;
  bottom: 11%;
  width: 27%;
  height: 25%;
}

.mini-map #fDNHH.active, .mini-map #fDNHH:hover {
    background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_history%20of%20innovation_on.png");
}  

.mini-map #cSiWb {
  background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_advancing%20brain%20health_off.png");
  left: 45%;
  top: 58%;
  width: 25%;
  height: 25%;
}

.mini-map #cSiWb.active, .mini-map #cSiWb:hover {
    background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_advancing%20brain%20health_on.png");
}  

.mini-map #SOiOd {
  background-color: white;
  background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_begin%20tour.png");
  left: 44%;
  bottom: 0;
  width: 19%;
  height: 4%;
}

.mini-map #SOiOd.active, .mini-map #SOiOd:hover {
background-color: #d52b1e;
color: white;
}  

.mini-map #rPxhD {
    background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_meet%20purina_off.png");
  right: 2%;
  bottom: 1%;
  width: 33%;
  height: 11%;
}

.mini-map #rPxhD.active, .mini-map #rPxhD:hover {
    background-image: url("https://storage.googleapis.com/purina-virtual-experience/micrio/map_meet%20purina_on.png");
}  
