.modal-content {
  justify-content: center;
  align-items: center;
  background: transparent;
}
.modal-dialog#welcome .modal-content {
  background: rgba(0, 0, 0, 75%);
}
.modal-body {
  position: relative;
  padding: 0;
  pointer-events: all;
}

.modal-body img {
  width: 50vw;
  height: auto;
}

.close {
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  outline: none;
  border: 0;  
}

.close img {
  width: 2vw;
  height: 2vw;
}

p {
  color: white;
  margin: 10%;
}