@font-face {
  font-family: Antenna;
  src:
    url("https://storage.googleapis.com/purina-virtual-experience/design/fonts/Antenna-Regular.otf")
    format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: Antenna;
  src: 
    url("https://storage.googleapis.com/purina-virtual-experience/design/fonts/Antenna-Bold.otf")
    format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: Antenna;
  src: 
    url("https://storage.googleapis.com/purina-virtual-experience/design/fonts/Antenna-Thin.otf")
    format("opentype");
  font-weight: 100;
}

body {
  margin: 0;
  font-family: Antenna;
  color: white;
}

h1 {
  font-family: Antenna;
  font-weight: 400;
  font-size: 2.5rem;
  justify-self: center;
  align-self: center;
  text-align: start;
  font-weight: normal;
}

h2 {
  font-family: Antenna;
  font-size: 2rem;
  font-weight: 100;
  text-align: start;
  font-weight: normal;
}

h3 {
  font-family: Antenna;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: start;
  font-weight: normal;
}

h4 {
  font-family: Antenna;
  font-size: 1.25rem;
  font-weight: 100;
  text-align: start;
  font-weight: normal;
}

p {
  font-size: 1rem;
}
