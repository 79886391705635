micr-io .navigation-arrow > button {
  transform: rotate3d(1, 0, 0, 70deg) scale3d(2, 3, 2);
}

micr-io .navigation-arrow > button:hover {
  transform: rotate3d(1, 0, 0, 70deg) scale3d(2.5, 3.5, 2);
}

micr-io .exhibit.marker > button {
  height: 100px;
  width: 100px;
}

micr-io .exhibit.marker > button:hover {
  height: 110px;
  width: 110px;
}

micr-io .marker button {
  opacity: 1;
  max-width: 10vh;
  max-height: 10vh;
}

micr-io .popover {
  max-width: none;
}

micr-io .enter-tour,
micr-io .enter-tour button {
  filter: none;
  max-width: 20vw;
  max-height: 20vh;
}

micr-io .popover {
  right: 0;
  left: auto;
}

micr-io .popover.popover section {
  background-color: transparent;
}

micr-io aside.popover {
  background-color: transparent;
}

.enter-tour button:hover {
  transition-duration: 0.2s;
  background-image: url("https://storage.googleapis.com/purina-virtual-experience/design/micrio%20assets/enter-tour-active.png");
}

micr-io div.container.progress-bar {
  display: none;
}

micr-io label.marker {
  font-size: 2vh;
}
